import { CommonLayoutProps, MenuOffset } from "@akte-mord/common";
import { Footer } from "../footer";
import { Header } from "../header";
import { NextSeo } from "next-seo";
import React from "react";

export const BaseWithFollowUsLayout: React.FC<CommonLayoutProps> = ({
  children,
  description,
  noindex = false,
  title,
}) => {
  return (
    <React.Fragment>
      <NextSeo title={title} description={description} noindex={noindex} />
      <Header />
      <MenuOffset />
      <main>{children}</main>
      {/* <FollowUs /> */}
      <Footer />
    </React.Fragment>
  );
};
