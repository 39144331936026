import { BaseWithFollowUsLayout } from "../layouts";
import { Box, Typography } from "@mui/material";
import { Container, InlineLink, NextPageWithLayout, TypoEffect404 } from "@akte-mord/common";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import React from "react";
import type { GetStaticProps } from "next";
import TypoImage404 from "../../public/typo-effects/404-typo-bg.jpg";

const ErrorPage: NextPageWithLayout = () => (
  <Container>
    <Box sx={{ maxWidth: "md", mx: "auto" }}>
      <Box sx={{ maxWidth: "sm", mx: "auto", mb: 8 }}>
        <TypoEffect404 imageSrc={TypoImage404.src} />
      </Box>
      <Typography variant="h1" textAlign="center">
        Pardon
      </Typography>
      <Typography variant="body1" paragraph textAlign="center" sx={{ mt: 2 }}>
        Quelque chose s'est mal passé ici. Nous n'avons pas trouvé la page que tu cherches. Veuillez
        utiliser la navigation pour accéder à une autre page.
      </Typography>
      <Typography variant="body1" paragraph textAlign="center">
        Si tu as des questions, tu peux aussi nous contacter à tout moment à l'adresse{" "}
        <InlineLink href="mailto:mail@akte-mord.de">support@cold-case.fr</InlineLink>.
      </Typography>
    </Box>
  </Container>
);

ErrorPage.getLayout = (page) => (
  <BaseWithFollowUsLayout
    title="404 – COLD CASE"
    description="Quelque chose s'est mal passé ici. Nous n'avons malheureusement pas pu trouver la page recherchée."
    noindex={true}
  >
    {page}
  </BaseWithFollowUsLayout>
);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(locale
        ? await serverSideTranslations(locale, ["c-common", "c-user-consent-management"], i18nConfig)
        : {}),
    },
  };
};

export default ErrorPage;
